import React, {Component} from 'react';
import MobileHeaderLayout from "./MobileHeaderLayout";
import DesktopHeaderLayout from "./DesktopHeaderLayout";
import NavigationSocialMediaWidget from "./widget/NavigationSocialMediaWidget";

import './styles/Header.css'


export class Header extends Component {
    static className = Object.freeze("Header")

    /**
     * @private
     * @type {String}
     */
    _HEADER_BACKGROUND_STATE_DISPLAY = Object.freeze("rgba(234, 234, 234, 0.78)");

    /**
     * @private
     * @type {String}
     */
    _HEADER_BACKGROUND_STATE_HIDE = Object.freeze("none");

    /**
     * @private
     * @type {String}
     */
    _HEADER_COLOR_STATE_DISPLAY = Object.freeze("black");

    /**
     * @private
     * @type {String}
     */
    _HEADER_COLOR_STATE_HIDE = Object.freeze("white");

    /**
     * @private
     * @type {String}
     */
    _HEADER_BACKDROP_FILTER_STATE_DISPLAY = Object.freeze("blur(8px)");

    /**
     * @private
     * @type {String}
     */
    _HEADER_BACKDROP_FILTER_STATE_HIDE = Object.freeze("none");

    /**
     * @private
     * @type {Number}
     */
    _prevScrollPos = 0;




    constructor(props, context) {
        super(props, context);

        this.state = {
            style: {
                background: this._HEADER_BACKGROUND_STATE_HIDE,
                color: this._HEADER_COLOR_STATE_HIDE,
                backdropFilter: this._HEADER_BACKDROP_FILTER_STATE_HIDE
            },
            windowWidth: window.innerWidth,
        }
        Header.__instance = this;
    }

    /**
     * @return {Header}
     */
    static getInstance() {
        if (!Header.__instance) new Header();
        return Header.__instance;
    }

    componentDidMount() {
        window.addEventListener("resize", () => this.setState({windowWidth: window.innerWidth}))

        window.addEventListener("scroll", () => {
            const currentScrollPos = window.pageYOffset;
            const introSectionEndPos = window.innerHeight;
            const introSectionEndPosOffset = introSectionEndPos / 8;


            if (currentScrollPos >= introSectionEndPos - introSectionEndPosOffset) {
                this.displayBackground(true);

                if (currentScrollPos > this._prevScrollPos) {
                    //Going down
                    document.querySelector("." + Header.className)?.classList.add("hide");
                    document.querySelector("." + Header.className)?.classList.remove("show");
                }
                else {
                    //Going up
                    document.querySelector("." + Header.className)?.classList.remove("hide");
                    document.querySelector("." + Header.className)?.classList.add("show");
                }
            }
            else {
                this.displayBackground(false);

                document.querySelector("." + Header.className)?.classList.remove("hide");
                document.querySelector("." + Header.className)?.classList.add("show");
            }

            this._prevScrollPos = currentScrollPos;
        })
    }

    /**
     * @param {Boolean} displayBackground
     */
    displayBackground(displayBackground) {
        if (displayBackground) {
            const style = {
                background: this._HEADER_BACKGROUND_STATE_DISPLAY,
                color: this._HEADER_COLOR_STATE_DISPLAY,
                backdropFilter: this._HEADER_BACKDROP_FILTER_STATE_DISPLAY,
            }
            this.setState({style});
            NavigationSocialMediaWidget.getInstance()?.setColorBlack();
        }
        else {
            const style = {
                background: this._HEADER_BACKGROUND_STATE_HIDE,
                color: this._HEADER_COLOR_STATE_HIDE,
                backdropFilter: this._HEADER_BACKDROP_FILTER_STATE_HIDE,
            }
            this.setState({style});
            NavigationSocialMediaWidget.getInstance()?.setColorWhite();
        }
    }

    render() {
        return (
            <div
                className={Header.className}
                style={this.state.style}
            >
                {
                    this.state.windowWidth < 1480 ?
                        <MobileHeaderLayout />
                    :
                        <DesktopHeaderLayout />
                }
            </div>
        );
    }
}