import React, {Component} from 'react';

class AboutAppWatchText extends Component {
    static className = Object.freeze("AboutAppWatchText");



    render() {
        return (
            <div className={AboutAppWatchText.className}>
                <p>Browse the <b>catalog</b> section to get familiar with vintage, past and new watch models. If you are looking for a particular watch visit the <b>brand</b> page, simply swipe through the feed and deep dive into the history and inventory of watches, collector items and more. Watch Vault share <b>insights</b> the watch industry has kept secret for decades.  </p>
            </div>
        );
    }
}

export default AboutAppWatchText;