import React, {Component} from 'react';
import {ReactComponent as Logo} from "../../../../assets/adaptiveLogo.svg";
import {ReactComponent as LogoIcon} from "../../../../assets/logo-icon.svg";

class LogoWidget extends Component {
    static className = Object.freeze("LogoWidget");


    /**
     * @private
     * @param {Event} e
     */
    _onLogoClick(e) {
        window.scroll({
            top: 1,
        })
    }

    render() {
        return (
            <div
                className={LogoWidget.className + " noSelect"}
                onClick={(e) => this._onLogoClick(e)}
            >
                <div className={"LogoIcon"}>
                    <LogoIcon />
                </div>
                <Logo />
            </div>
        );
    }
}

export default LogoWidget;