import React, {Component} from 'react';
import MediaContentPresenter from "../presenter/MediaContentPresenter";

import PromoVideo4k from '../../../../assets/4k.mp4';
import PromoVideoHD from '../../../../assets/1080p.mp4';
import PromoVideo720 from '../../../../assets/720.mp4';

import IntroImage from '../../../../assets/watch-vault1.svg';
import IntroImageHD from '../../../../assets/watch-vault-1080.svg';
import IntroImage1440 from '../../../../assets/watch-vault-1440.svg';

import '../styles/MediaContentView.css';



const SECONDS = (numSeconds) => numSeconds * 1000;

class MediaContentView extends Component {
    static VIEW_STATE_VIDEO = 33;
    static VIEW_STATE_INTRO_IMAGE = 44;

    constructor(props, context) {
        super(props, context);
        this.className = "MediaContentView";
        this.state = {
            viewState: MediaContentView.VIEW_STATE_INTRO_IMAGE,
            isVideoLoaded: false,
            isImgLoaded: false
        }

        MediaContentPresenter.bindView(this);
    }

    onVideoDataLoaded() {
        this.setState({
            isVideoLoaded: true
        });
        const video = document.querySelector("." + this.className + "__video");
        video.play()
            .then(() => {
                this.handleViewState();
            })
    }

    onVideoEnded() {
        setTimeout(
            () => {
                MediaContentPresenter.onFinished();
            },
            SECONDS(2)
        );
    }

    handleViewState() {
        switch (this.state.viewState) {
            case MediaContentView.VIEW_STATE_INTRO_IMAGE:
                setTimeout(
                    () => {
                        this.setState({
                            viewState: MediaContentView.VIEW_STATE_VIDEO
                        });
                        this.handleViewState();
                    },
                    SECONDS(4)
                )
                break;

            case MediaContentView.VIEW_STATE_VIDEO:
                setTimeout(
                    () => MediaContentPresenter.onVideoTransitionDark(),
                    SECONDS(10.8)
                )
                break;

            default:
                break;
        }
    }

    onImgLoaded() {
        this.setState({isImgLoaded: true})
        MediaContentPresenter.onVideoTransitionLight();
    }

    getPromoVideoSrc() {
        if (window.innerHeight <= 720) return PromoVideo720;
        if (window.innerHeight <= 1080) return PromoVideoHD;
        if (window.innerHeight > 1080) return PromoVideo4k;
    }

    getImgSrc() {
        if (window.innerHeight <= 1080) return IntroImageHD;
        if (window.innerHeight <= 1440) return IntroImage1440;
        if (window.innerHeight > 1440) return IntroImage;
    }

    returnToIntro() {
        this.setState({
            viewState: MediaContentView.VIEW_STATE_INTRO_IMAGE
        });
    }

    render() {

        return (
            <div className={this.className} >
                <div
                    className={this.className + "__img"}
                    style={{ opacity: this.state.viewState === MediaContentView.VIEW_STATE_INTRO_IMAGE ? 1 : 0 }}
                >
                    { this.state.isImgLoaded ?
                        ""
                        :
                        <div className={"LoadingWrapper"}>
                            <p>Loading...</p>
                        </div>
                    }
                    < img
                        src={this.getImgSrc()}
                        alt={""}
                        onLoad={() => this.onImgLoaded()}
                    />
                </div>

                <video
                    className={this.className + "__video"}
                    src={this.getPromoVideoSrc()}
                    playsInline
                    muted
                    preload={"auto"}
                    onCanPlayThrough={() => this.onVideoDataLoaded()}
                    onEnded={() => this.onVideoEnded()}
                    style={{ opacity: this.state.viewState === MediaContentView.VIEW_STATE_VIDEO ? 1 : 0 }}
                />

            </div>
        );
    }

}

export default MediaContentView;