import React, {Component} from 'react';
import ProfileImage from "../../../../../../../assets/Screenshot_profile.png"

class AboutAppVaultImg extends Component {
    static className = Object.freeze("AboutAppVaultImg");



    render() {
        return (
            <div className={AboutAppVaultImg.className}>
                <img src={ProfileImage} alt={""}/>
            </div>
        );
    }
}

export default AboutAppVaultImg;