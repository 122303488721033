import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth"
import FirebaseConfig from "./certificate/FirebaseConfig";


const firebaseApp = firebase.initializeApp(FirebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();


export { auth, provider };

export default db;