import React, {Component} from 'react';

class AboutAppVaultHeader extends Component {
    static className = Object.freeze("AboutAppVaultHeader");



    render() {
        return (
            <div className={AboutAppVaultHeader.className}>
                <h4>VAULT</h4>
            </div>
        );
    }
}

export default AboutAppVaultHeader;