import React, {Component} from 'react';
import CrowdfundingTextWidget from "./component/CrowdfundingTextWidget";
import CrowdfundingExtCard from "./component/CrowdfundingExtCard";

import "./styles/CrowdfundingView.css"


export class CrowdfundingView extends Component {
    static className = Object.freeze("CrowdfundingView");


    constructor(props, context) {
        super(props, context);
        CrowdfundingView.__instance = this;
    }

    /**
     * @return {CrowdfundingView}
     */
    static getInstance() {
        return CrowdfundingView.__instance;
    }

    scrollTo() {
        document.querySelector(`.${CrowdfundingView.className}`).scrollIntoView();
    }

    componentDidMount() {
        // document.addEventListener(
        //     "scroll",
        //     () => {
        //         const currentPos = window.pageYOffset;
        //         if (currentPos >= document.querySelector(`.${CrowdfundingView.className}`).getBoundingClientRect().top) {
        //             document.querySelector(`.CrowdfundingTextWidget__title__h2`)?.classList.add("open");
        //             console.log(document.querySelector(`.CrowdfundingTextWidget__title__h2`))
        //         }
        //         else {
        //             document.querySelector(`.CrowdfundingTextWidget__title__h2`)?.classList.remove("open");
        //         }
        //     }
        // )
    }

    render() {
        return (
            <div className={CrowdfundingView.className}>
                <div className={"columns"}>
                    <CrowdfundingTextWidget />
                    <CrowdfundingExtCard />
                </div>
            </div>
        );
    }
}