import React, {Component} from 'react';

class AboutAppSocializeText extends Component {
    static className = Object.freeze("AboutAppSocializeText");



    render() {
        return (
            <div className={AboutAppSocializeText.className}>
                <p>Follow friends, family, colleagues, famous collectors, brands and magazines or gather an audience of your own. Join fellow watch enthusiasts and collectors in popular communities or create your very own community group to share and discuss your watch passion with a world of likeminded companions. We collaborate with magazines, brands, bloggers and influencers in the watch world to give you curated content and bring a new meaning to the very unique watch you are wearing on your wrist.</p>
            </div>
        );
    }
}

export default AboutAppSocializeText;