import React, {Component} from 'react';

class CrowdfundingTextWidget extends Component {
    static className = Object.freeze("CrowdfundingTextWidget");



    _onJoinBtnClick(e) {
        window.open(
            "https://folkeinvest.no/kampanje/watch-vault-as",
            "_blank"
        ).focus();
    }

    render() {
        return (
            <div className={CrowdfundingTextWidget.className + " column"}>
                <div className={CrowdfundingTextWidget.className + "__wrapper"}>
                    <div className={CrowdfundingTextWidget.className + "__markerContainer"}>
                        <div className={CrowdfundingTextWidget.className + "__marker"}>
                        </div>
                    </div>
                    <div className={CrowdfundingTextWidget.className + "__title"}>
                        <h2 className={`${CrowdfundingTextWidget.className}__title__h2`}>Welcome to the Watch Vault</h2>
                    </div>
                    <div className={CrowdfundingTextWidget.className + "__txtContent"}>
                        <p>
                            We are proud to announce that 464 investors joined the crowdfunding of Watch Vault.
                            The funds are allocated to hiring new staff, launching the app and upcoming events for watch enthusiasts in Scandinavia. We welcome onboard all new investors!
                        </p>
                    </div>
                    <div
                        className={CrowdfundingTextWidget.className + "__joinBtn noSelect"}
                        onClick={(e) => this._onJoinBtnClick(e)}
                    >
                        <h5>READ MORE</h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrowdfundingTextWidget;