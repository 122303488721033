import firebase from "../../config/firebase";

const RegisterRepository = () => {
    const emailDbRef = firebase.collection("email");

    const getEmail = (email) => {
        return new Promise(
            (resolve, reject) => {

                emailDbRef.doc(email)
                    .get()
                    .then((email) => resolve(email))
                    .catch((err) => reject(err));
            }
        )
    }

    const setEmail = (email) => {
        return new Promise(
            (resolve, reject) => {

                const emailObj = {email: email, dateRegistered: new Date(Date.now())};

                emailDbRef
                    .doc("" + email)
                    .set(emailObj)
                    .then(() => resolve(emailObj))
                    .catch((err) => reject(err));
            }
        )
    }

    const registerEmail = (email) => {
        return setEmail(email)
    }

    return {
        registerEmail
    }
}

export default RegisterRepository;