import HeaderView from "../component/HeaderView";
import HeroPresenter from "./HeroPresenter";


const MediaContentPresenter = (() => {

    let _view = null;
    let _titleView = null;

    const bindView = (view) => {
        _view = view
        HeroPresenter.bindMediaContentView(view);
    }

    const bindTitleView = (titleView) => {
        _titleView = titleView;
    }

    const onVideoStarted = () => {

    }

    const onVideoTransitionDark = () => {
        if (_titleView) {
            _titleView.setColor(HeaderView.COLOR_BLACK)
        }
    }

    const onVideoTransitionLight = () => {
        if (_titleView) {
           _titleView.setColor(HeaderView.COLOR_WHITE);
        }
    }

    const onFinished = () => {
        HeroPresenter.onViewFinished();
    }

    return {
        bindView,
        bindTitleView,
        onVideoStarted,
        onVideoTransitionDark,
        onVideoTransitionLight,
        onFinished,
    }
}) ();

export default MediaContentPresenter;
