import React, {Component} from 'react';
import AboutAppHeader from "./component/AboutAppHeader";
import AboutAppIntro from "./component/intro/AboutAppIntro";

import '../../styles/AboutAppWidget.css'
import AboutAppSocialize from "./component/socialize/AboutAppSocialize";
import AboutAppWatch from "./component/watch/AboutAppWatch";
import AboutAppSmartPrice from "./component/smartPrice/AboutAppSmartPrice";
import AboutAppVault from "./component/vault/AboutAppVault";
import PromoVid from "./component/PromoVId/PromoVid";

class AboutAppWidget extends Component {
    static className = Object.freeze("AboutAppWidget");



    render() {
        return (
            <div className={AboutAppWidget.className}>
                <AboutAppHeader/>
                <PromoVid />
                <AboutAppIntro />
                <AboutAppSocialize />
                <AboutAppWatch />
                <AboutAppVault />
                <AboutAppSmartPrice />
            </div>
        );
    }
}

export default AboutAppWidget;