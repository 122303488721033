import React, {Component} from 'react';
import AboutAppSocializeImg from "./AboutAppSocializeImg";
import AboutAppSocializeText from "./AboutAppSocializeText";
import AboutAppSocializeHeader from "./AboutAppSocializeHeader";

class AboutAppSocialize extends Component {
    static className = Object.freeze("AboutAppSocialize");



    render() {
        return (
            <div className={AboutAppSocialize.className}>
                <AboutAppSocializeHeader />
                <div className="is-hidden-mobile AboutApp--container">
                    <div className="columns contentContainer">
                        <div className="column imgContainer">
                            <AboutAppSocializeImg />
                        </div>
                        <div className="column">
                            <AboutAppSocializeText />
                        </div>
                    </div>
                </div>
                <div className="is-hidden-tablet AboutApp--container">
                    <AboutAppSocializeImg />
                    <AboutAppSocializeText />
                </div>
            </div>
        );
    }
}

export default AboutAppSocialize;