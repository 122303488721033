import React, {Component} from 'react';
import MobileNavigationMenuWidget from "./MobileNavigationMenuWidget";
import {ReactComponent as MenuIcon} from "../../../../assets/icons/menuIcon.svg";


class MobileNavigationWidget extends Component {
    static className = Object.freeze("MobileNavigationWidget");



    constructor(props, context) {
        super(props, context);
        this.state = {
            displayFullscreenMenu: false,
        }
    }

    /**
     * @private
     * @param {Event} e
     */
    _onMenuBtnCLick(e) {
        this.setState({
            displayFullscreenMenu: true,
        })
    }

    /**
     * @private
     */
    _onMenuExit() {
        this.setState({
            displayFullscreenMenu: false,
        })
    }

    render() {

        return (
            <div className={MobileNavigationWidget.className}>

                {
                    this.state.displayFullscreenMenu ?
                        <MobileNavigationMenuWidget onMenuExit={() => this._onMenuExit()}/>
                    :
                        <div
                            className={MobileNavigationWidget.className + "__menuIcon noSelect"}
                            onClick={(e) => this._onMenuBtnCLick(e)}
                        >
                            <MenuIcon />
                        </div>
                }
            </div>
        );
    }
}

export default MobileNavigationWidget;