import React, {Component} from 'react';
import MediaContentPresenter from "../presenter/MediaContentPresenter";
import Logo from "../../../../assets/logo.svg";

import '../styles/HeaderView.css';

const STAGE_DESKTOP = 0;
const STAGE_MOBILE = 1;

class HeaderView extends Component {

    static COLOR_BLACK = "black";
    static COLOR_WHITE = "white";

    constructor(props, context) {
        super(props, context);
        this.className = "HeaderView";

        MediaContentPresenter.bindTitleView(this);
    }

    setColor(color = HeaderView.COLOR_BLACK) {

        const stages = document.querySelectorAll("#" + this.className + "__logo");

        const stage = window.innerWidth < 1080 ? stages[STAGE_MOBILE] : stages[STAGE_DESKTOP];

        if (stage) {
            if (color === HeaderView.COLOR_WHITE) {
                stage.classList.toggle("HeaderView__logo--white")
            }
            else {
                stage.classList.remove("HeaderView__logo--white");
            }
        }
    }

    render() {
        return (
            <div className={this.className} >
                <div id={this.className + "__logo"} className={this.className + "__logo is-hidden-mobile"} >
                    <img
                        src={Logo}
                        alt={"Watch Vault"}
                        width={"30%"}
                    />
                </div>
                <div id={this.className + "__logo"} className={this.className + "__logo is-hidden-tablet"} >
                    <img
                        src={Logo}
                        alt={"Watch Vault"}
                        width={"80%"}
                    />
                </div>
            </div>
        );
    }
}

export default HeaderView;