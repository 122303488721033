import React, {Component} from 'react';

import './Toast.css'

export class Toast extends Component {

    static TYPE_EXTENDED = 0;
    static TYPE_COMPACT = 1;

    constructor(props, context) {
        super(props, context);
        this.state = {
            className: "toast",
            message: props.message || "",
            type: Toast.TYPE_COMPACT,
            blurredBackground: false,
        }
        if (this.state.message === "") {
            console.error("Toast message not properly configured.")
        }
        else {
            setTimeout(
                () => {
                    this.setState({
                        className: "toast hide"
                    });
                },
                5000
            )
        }
    }

    render() {
        const msgWidth = this.state.message.length;
        const width = msgWidth + 4;

        return (
            <div className={this.state.className} style={{width: width + "ch"}}>
                <div className="toast-message" style={{width: msgWidth + "ch"}}>
                    {this.state.message ? this.state.message : ""}
                </div>
            </div>
        );
    }
}

export default Toast;