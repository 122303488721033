import React, {Component} from 'react';

class PromoVid extends Component {
    static className = Object.freeze("PromoVid");



    render() {
        return (
            <div className={PromoVid.className}>
                <iframe
                    className={`${PromoVid.className}__promoVid`}
                    src="https://www.youtube.com/embed/VP_kw2IrU2Q"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        );
    }
}

export default PromoVid;