import React, {Component} from 'react';
import {ReactComponent as Logo} from "../../../../assets/logo.svg";

class FooterLogoWidget extends Component {
    static className = Object.freeze("FooterLogoWidget");



    render() {
        return (
            <div className={FooterLogoWidget.className}>
                <Logo />
            </div>
        );
    }
}

export default FooterLogoWidget;