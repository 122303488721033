import MediaContentPresenter from "./MediaContentPresenter";
import { HomePage } from "../../../pages/home";


const HeroPresenter = (() => {

    let _view = null;
    let _getNotifiedBtnView = null;
    let _mediaContentView = null;

    const bindView = (view) => {
        _view = view;
    }

    const bindMediaContentView = (view) => {
        _mediaContentView = view;
    }

    const bindGetNotifiedBtnView = (getNotifiedBtnView) => {
        _getNotifiedBtnView = getNotifiedBtnView;
    }

    const initGetNotifiedView = () => {
        HomePage.displayGetNotifiedSection();
    }

    const closeGetNotified = () => {
        HomePage.exitGetNotified();
    }

    const onViewFinished = () => {
        if (_view) _view.displayOutroMessage();
        if (_getNotifiedBtnView) _getNotifiedBtnView.enablePulse();
        setTimeout(
            () => {
                _view.hideOutroMessage();
                MediaContentPresenter.onVideoTransitionLight();
                _mediaContentView.returnToIntro();
            },
            30000
        )
    }

    return {
        bindView,
        bindGetNotifiedBtnView,
        initGetNotifiedView,
        closeGetNotified,
        onViewFinished,
        bindMediaContentView
    }

}) ();

export default HeroPresenter;