import React from 'react';
import './Styles.css'
import DownloadFabContainer from "./DownloadFabContainer";

function DownloadFab() {
    return (
        <div className={"DownloadFab"}>
            <DownloadFabContainer />
        </div>
    );
}

export default DownloadFab;