import React, {Component} from 'react';
import GetNotifiedPresenter from "../../presenter/GetNotifiedPresenter";
import RegisterViewWidget from "./widget/RegisterViewWidget";
import SuccessViewWidget from "./widget/SuccessViewWidget";
import { Toast } from "../../../../component/toast";


import '../../styles/GetNotifiedView.css'


const secondsToMs = (seconds) => seconds * 1000;


export class GetNotifiedView extends Component {

    static VIEW_STATE__INPUT_FORM = "FORM";
    static VIEW_STATE__SUCCESS = "SUCCESS";

    constructor(props, context) {
        super(props, context);
        this.className = "GetNotifiedView";

        GetNotifiedPresenter.bindView(this);

        this.state = {
            viewState: GetNotifiedView.VIEW_STATE__INPUT_FORM,
            error: {error: false, errorMessage: ""},
        }
    }

    exitGetNotifiedView() {
        GetNotifiedPresenter.close();
    }

    ignoreParentListener(e) {
        e.stopPropagation();
    }

    setViewState(viewState) {
        if (viewState === GetNotifiedView.VIEW_STATE__INPUT_FORM || GetNotifiedView.VIEW_STATE__SUCCESS)
            this.setState({viewState: viewState});
    }

    displayEmailError() {
        this.setState({error: {error: true, errorMessage: "Oops! The email provided is not an email"}})
        setTimeout(
            () => {
                this.resetError();
            },
            secondsToMs(10)
        )
    }

    displayGenericError() {
        this.setState({error: {error: true, errorMessage: "Oops! An unexpected error occurred"}})
        setTimeout(
            () => {
                this.resetError();
            },
            secondsToMs(10)
        )
    }

    resetError() {
        this.setState({error: {error: false, errorMessage: ""}})
    }

    render() {
        return (
            <div className={this.className + "--background"} onClick={() => this.exitGetNotifiedView() }>
                {this.state.viewState === GetNotifiedView.VIEW_STATE__INPUT_FORM ?
                    <>
                        <div className={this.className + " is-hidden-mobile"} onClick={(e) => this.ignoreParentListener(e)}>
                            <RegisterViewWidget />
                        </div>
                        <div className={this.className + "--mobile is-hidden-tablet"} onClick={(e) => this.ignoreParentListener(e)}>
                            <RegisterViewWidget />
                        </div>
                    </>
                    : ""}

                {this.state.viewState === GetNotifiedView.VIEW_STATE__SUCCESS ? <SuccessViewWidget /> : ""}
                {this.state.error.error ? <Toast message={this.state.error.errorMessage} /> : ""}
            </div>
        );
    }
}