import React, {Component} from 'react';
import LogoWidget from "./widget/LogoWidget";
import MobileNavigationWidget from "./widget/MobileNavigationWidget";
import { DownloadFab } from "../download-fab";
import './styles/MobileHeader.css'

class MobileHeaderLayout extends Component {
    static className = Object.freeze("MobileHeaderLayout");



    render() {
        return (
            <div className={MobileHeaderLayout.className}>
                <LogoWidget />
                <MobileNavigationWidget />
                <DownloadFab />
            </div>
        );
    }
}

export default MobileHeaderLayout;