import React, {Component} from 'react';
import WatchListingImage from "../../../../../../../assets/screenshot-listing.png"

class AboutAppWatchImg extends Component {
    static className = Object.freeze("AboutAppWatchImg");



    render() {
        return (
            <div className={AboutAppWatchImg.className}>
                <img src={WatchListingImage} alt={""}/>
            </div>
        );
    }
}

export default AboutAppWatchImg;