import React, {Component} from 'react';
import ScrollHandler from "../../common/utils/scroll-handler";
import PartnerLogo from "./component/PartnerLogo";
import {ReactComponent as LogoKlokkeriet} from "../../../assets/partnerLogos/logo_klokkeriet.svg";
import {ReactComponent as LogoPrjctOslo} from "../../../assets/partnerLogos/logo_prjct-oslo.svg";
import {ReactComponent as LogoHeyerdahl} from "../../../assets/partnerLogos/logo_heyerdahl.svg";
import {ReactComponent as LogoTrustshare} from "../../../assets/partnerLogos/logo_trustshare.svg";
import {ReactComponent as LogoCloudvisor} from "../../../assets/partnerLogos/logo_cloudvisor.svg";
import {ReactComponent as LogoAws} from "../../../assets/partnerLogos/logo_aws.svg";
import {ReactComponent as LogoNimbus} from "../../../assets/partnerLogos/logo_nimbus.svg";
import {ReactComponent as LogoYtili} from "../../../assets/partnerLogos/logo_ytili.svg";
import {ReactComponent as LogoSkattefunn} from "../../../assets/partnerLogos/logo_skattefunn.svg";
import {ReactComponent as LogoInnovasjonNorge} from "../../../assets/partnerLogos/logo_innoNorge.svg";

import "./styles/PartnersView.css"

export class PartnersView extends Component {
    static className = Object.freeze("PartnersView");


    constructor(props, context) {
        super(props, context);
        PartnersView.__instance = this;
    }

    /**
     * @return {PartnersView}
     */
    static getInstance() {
        return PartnersView.__instance;
    }

    scrollTo() {
        ScrollHandler?.lockAnim(true);

        document.querySelector(`.${PartnersView.className}`)?.scrollIntoView();

        setTimeout(
            () => {
                ScrollHandler?.lockAnim(false);
            },
            2000
        )
    }

    render() {
        return (
            <div className={PartnersView.className}>
                <div className={`${PartnersView.className}__title`}>
                    <h2>PARTNERS</h2>
                </div>
                <div className={`${PartnersView.className}--wrapper columns`}>
                    <PartnerLogo
                        name={"Klokkeriet"}
                        url={"https://klokkeriet.no/"}
                    >
                        <LogoKlokkeriet />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"PRJCT OSLO"}
                        url={"https://www.prjctoslo.com/"}
                    >
                        <LogoPrjctOslo />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"Gullsmed Heyerdahl"}
                        url={"https://heyerdahl.no/"}
                    >
                        <LogoHeyerdahl />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"Trustshare"}
                        url={"https://www.trustshare.co/"}
                    >
                        <LogoTrustshare />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"Cloudvisor"}
                        url={"https://www.cloudvisor.eu/"}
                    >
                        <LogoCloudvisor />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"Amazon Web Services"}
                        url={"https://aws.amazon.com/"}
                    >
                        <LogoAws />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"Nimbus regnskap"}
                        url={"https://www.nimbusregnskap.no/"}
                    >
                        <LogoNimbus />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"Ytili"}
                        url={"https://ytili-worldchicago.org/"}
                    >
                        <LogoYtili />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"Skatttefunn"}
                        url={"https://www.skattefunn.no/"}
                    >
                        <LogoSkattefunn />
                    </PartnerLogo>
                    <PartnerLogo
                        name={"InnovasjonNorge"}
                        url={"https://www.innovasjonnorge.no/"}
                    >
                        <LogoInnovasjonNorge />
                    </PartnerLogo>
                </div>
            </div>
        );
    }
}