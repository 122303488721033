import React, {Component} from 'react';
import FooterLogoWidget from "./component/FooterLogoWidget";
import FooterStatementWidget from "./component/FooterStatementWidget";

import "./styles/FooterView.css"

export class FooterView extends Component {
    static className = Object.freeze("FooterView");

    render() {
        return (
            <div className={FooterView.className}>
                <FooterLogoWidget />
                <FooterStatementWidget />
            </div>
        );
    }
}