import React from 'react';

const onClick = (event) => {
    window.open("https://onelink.to/watchvault", "_blank");
}

function DownloadFabButton() {
    return (
        <div className={"DownloadFab__button"} onClick={onClick}>
            <h5>DOWNLOAD</h5>
        </div>
    );
}

export default DownloadFabButton;