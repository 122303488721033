import React, {Component} from 'react';

class AboutAppVaultText extends Component {
    static className = Object.freeze("AboutAppVaultText");



    render() {
        return (
            <div className={AboutAppVaultText.className}>
                <p>Your watch vault is always protected and evaluated with smart analytics on the palm of your hand. Discover trending watches and add them to your collection. Buy a watch or win auctions to increase your personal collection. Get recently released watches straight from the brand or look for unique historical pieces. Your personal vault will digitalize your watch collection and make a lasting impact on the watch industry. Together we make a great leap towards a transparent, sustainable and safe watch market.</p>
            </div>
        );
    }
}

export default AboutAppVaultText;