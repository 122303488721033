import React, {Component} from 'react';
import ScrollHandler from "../../common/utils/scroll-handler";
import StaffMemberWidget from "./component/StaffMemberWidget";

import "./styles/StaffView.css"

export class StaffView extends Component {
    static className = Object.freeze("StaffView");


    constructor(props, context) {
        super(props, context);
        StaffView.__instance = this;
    }

    /**
     * @return {StaffView}
     */
    static getInstance() {
        return StaffView.__instance;
    }

    scrollTo() {
        ScrollHandler?.lockAnim();

        document.querySelector(`.${StaffView.className}`)?.scrollIntoView({
            behavior: "smooth"
        });

        setTimeout(
            () => {
                ScrollHandler?.lockAnim(false);
            },
            2000
        )
    }

    render() {
        return (
            <div className={StaffView.className}>
                <div className={StaffView.className + "__container"}>
                    <div className={StaffView.className + "__title"}>
                        <div className={StaffView.className + "__marker"}>
                        </div>
                        <h2>STAFF</h2>
                    </div>
                    <div className={StaffView.className + "__columns columns has-12 is-multiline"}>
                        <StaffMemberWidget
                            name={"Christopher Robin Nordmo"}
                            jobTitle={"CEO"}
                            imgUrl={"https://i.ibb.co/X70GBPM/portrait-cr2.jpg"}
                            email={"ceo@watchvault.org"}
                        >
                            <p>
                                Master of Laws – University of Oslo
                                <br/>
                                <br/>
                                Have contributed to voluntary and non-political organizations for 15 years. Former member of the National Conscript Council in the Norwegian Armed Forces. Chief Safety Representative for Norwegian conscript soldiers and spokesperson for TMO/TVO. Worked 7 years in watchmaking during school and studies.
                                <br/>
                                <br/>
                                “Our aim is to build a global watch platform solving issues manufacturers, sellers and collectors are faced with in today´s market.”

                            </p>
                        </StaffMemberWidget>
                        <StaffMemberWidget
                            name={"Adrian Falch Karlsen"}
                            jobTitle={"CTO"}
                            email={"cto@watchvault.org"}
                            imgUrl={"https://i.ibb.co/FDbvLM9/portrait-adrian2.jpg"}
                        >
                            <p>
                                Studied computer engineering at Ramon Llull University La Salle in Barcelona and IT at Kristiania University College.
                                <br/>
                                <br/>
                                4 years of experience from technological start-up businesses. In addition, there are several years of experience from various freelance assignments and web development projects, primarily in Norway, but also in Spain. Adrian has more than 10 years of developer experience. As CTO he is in charge of the dev-team and system architecture.
                                <br/>
                                <br/>
                                “I seek perfection and reliable system architecture to drive major changes in the near future.”

                            </p>
                        </StaffMemberWidget>
                        <StaffMemberWidget
                            name={"Marco Alexander Gravbrøt"}
                            jobTitle={"DEVELOPER"}
                            imgUrl={"https://i.ibb.co/ccYwspk/portrait-marco2.jpg"}
                            email={""}
                        >
                            <p>
                                Studying information technology and programming at Kristiania University College.
                                <br/>
                                <br/>
                                Marco is very talented and works on the frontend development. He used to work for Komplett.no and has developed the app, "Binge".
                                <br/>
                                <br/>
                            </p>
                        </StaffMemberWidget>
                        <StaffMemberWidget
                            name={"Gvozden Miskovic"}
                            jobTitle={"DEVELOPER"}
                            email={""}
                            imgUrl={"https://i.ibb.co/3776YH7/portrait-gvozden.png"}
                        >
                            <p>
                                Studying computer engineering and business management at Ramon Llull University La Salle in Barcelona.
                                <br/>
                                <br/>
                                He wrote his first line of code as an 11-year-old and has since been involved in a number of projects, in several countries.

                            </p>
                        </StaffMemberWidget>
                        <StaffMemberWidget
                            name={"Kim Thi Nguyen"}
                            jobTitle={"COO"}
                            email={"coo@watchvault.org"}
                            imgUrl={"https://i.ibb.co/0mk7FCH/P2166416-2-2.jpg"}
                        >
                            <p>
                                Studied entrepreneurship at BI Norwegian Business School and Shipping and Logistics at Norges Merkantile Fagskole (Kristiania University College)
                                <br/>
                                <br/>
                                Over 10 years of experience with B2B sales within retail fashion and apparel. Latest experience from a scale-up logistics company that focus on last mile delivery with parcel lockers. Business development, project management and negotiations with commercial real estate.
                                <br/>
                                <br/>
                                Hobby and passion as a true watch geek that have collected and traded timepieces for over 15 years.
                                <br/>
                                <br/>
                                “It would be foolish not to follow the small details.”

                            </p>
                        </StaffMemberWidget>
                        <StaffMemberWidget
                            name={"Simen Sundblad"}
                            jobTitle={"CSR"}
                            email={"support@watchvault.org"}
                            imgUrl={"https://i.ibb.co/M8NWHSW/P2166434-2-2.jpg"}
                        >
                            <p>
                                Studying Frontend and app development at Kristiania University College.
                                <br/>
                                <br/>
                                Former employee for the Royal Norwegian Air Force where he was awarded with the Armed Forces Medal for International Operations.
                                <br/>
                                <br/>
                                Simen has experience from Kärcher AS where he worked for 3 years as a P.O.C for official dealerships and distributors of Kärcher products. He also spearheaded the modernisation of the department and was responsible for implementing fully digital solutions such as chat bots.
                                <br/>
                                <br/>
                                “I aim to please.”

                            </p>
                        </StaffMemberWidget>
                    </div>
                </div>
            </div>
        );
    }
}