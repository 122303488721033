import React, {Component} from 'react';
import { Header } from "../../component/header";
import { HeroSectionView } from "../../features/hero";
import { AboutView } from "../../features/about";
import { StaffView } from "../../features/the-staff";
import { PartnersView } from "../../features/partners";
import { FooterView } from "../../component/footer";
import { GetNotifiedView } from "../../features/hero/component/GetNotified/GetNotifiedView";

export class HomePage extends Component {

    /**
     * @private
     * @type {HomePage}
     */
    static __instance;


    constructor(props, context) {
        super(props, context);

        this.state = {
            displayGetNotifiedSection: false
        }

        HomePage.__instance = this;
    }

    static displayGetNotifiedSection() {
        HomePage.__instance?.setState({
            displayGetNotifiedSection: true
        })
    }

    static exitGetNotified() {
        HomePage.__instance?.setState({
            displayGetNotifiedSection: false
        })
    }

    render() {
        return (
            <>
                <Header />
                <HeroSectionView />
                {/*
                    <CrowdfundingView />
                */}
                <AboutView />
                <StaffView />
                <PartnersView />
                <FooterView />
                {this.state.displayGetNotifiedSection ? < GetNotifiedView /> : "" }
            </>
        );
    }
}