import React, {Component} from 'react';
import EmailIcon from "@material-ui/icons/Email";
import GetNotifiedPresenter from "../../../presenter/GetNotifiedPresenter";
import GoogleIcon from "../../../../../../assets/google-logo.svg"



class RegisterViewWidget extends Component {

    constructor(props, context) {
        super(props, context);
        this.className = "RegisterViewWidget";

        this.state = {
            googleIconLoaded: false
        }
    }

    registerBtnClicked(e) {
        e.preventDefault();

        const emailInput = this.getEmailInput();
        GetNotifiedPresenter.onRegister(emailInput)
    }

    getEmailInput() {
        const emailInput = document.querySelectorAll("#emailInput");

        return emailInput[0].value !== "" ? emailInput[0].value : emailInput[1].value;
    }

    registerWithGoogleBtnClicked() {
        GetNotifiedPresenter.registerWithGoogle().then(() => {})
    }

    googleIconLoaded() {
        this.setState({googleIconLoaded: true})
    }

    render() {
        return (
            <div className={this.className} >
                <h2>Get early access</h2>
                <div className={"separator"} />
                <h5>Get notified when the social marketplace app from Watch Vault is ready to launch on Google Play and Apple App Store.</h5>
                <div className={"separator"} />
                <div className={"separator"} />
                <div className="google-btn--wrapper">
                    <div className="google-btn" onClick={() => this.registerWithGoogleBtnClicked()}>
                        <img src={GoogleIcon} alt={"Google logo"} onLoad={() => this.googleIconLoaded()}/>
                        { this.state.googleIconLoaded ? <p>Register with Google</p> : ""}
                    </div>
                </div>
                <div className={"separator"} />
                <form className={"p-2"}>
                    <div className="m-1 field">
                        <p className="control has-icons-left has-icons-right">
                            <input id={"emailInput"} className="input is-rounded" type="email" placeholder="Email" />
                            <span className="icon is-small is-left">
                                    <EmailIcon className={this.className + "__emailIcon"}/>
                                </span>
                        </p>
                    </div>
                    <button
                        type={"submit"}
                        className="m-1 button is-info is-rounded"
                        onClick={(e) => this.registerBtnClicked(e)}
                    >
                        Register
                    </button>
                </form>
            </div>
        );
    }

}

export default RegisterViewWidget;