import React, {Component} from 'react';

class AboutAppIntroText extends Component {
    static className = Object.freeze("AboutAppIntroText");



    render() {
        return (
            <div className={AboutAppIntroText.className}>
                <p>Download <b>Watch Vault</b> to auction, sell or buy your next watch. Get hands on the ultimate watch app to gain insight on latest <b>trends</b> from the watch market or boost your knowledge from the <b>news</b> feed. Scroll through watch history in our <b>catalogs</b> from vintage to new watch models or upload your personal collection today. <b>Watch Vault</b> keeps all your watches safe inside the app, just like they would be in a vault, but better. The app lets you <b>verify</b> authenticity of your watch and store all relevant documents and data. The marketplace watches are <b>guaranteed</b> authentic. You can <b>report</b> your watch missing and fellow collectors will be able to help you find it. We also offer a comprehensive service to <b>keep track</b> of your upcoming maintenance service, collection valuation, insurance claims, as well, as store your warranty cards and certificates. It is all private, unless you decide to display your collection for public. Join the world’s biggest watch app and create your collection <b>today</b>. </p>
            </div>
        );
    }
}

export default AboutAppIntroText;