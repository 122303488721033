import React, {Component} from 'react';

class FooterStatementWidget extends Component {
    static className = Object.freeze("FooterStatementWidget");



    render() {
        return (
            <div className={FooterStatementWidget.className}>
                <p>© Copyright 2021. All rights reserved. Developed by Watch Vault AS, Norway.</p>
            </div>
        );
    }
}

export default FooterStatementWidget;