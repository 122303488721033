import React, {Component} from 'react';
import MediaContentView from "./component/MediaContentView";
import HeroPresenter from "./presenter/HeroPresenter";
import OutroMessageView from "./component/OutroMessageView";

import './styles/LandingPageView.css'

export class HeroSectionView extends Component {

    /**@private*/
    _prevScrollPosition = 0;

    constructor(props, context) {
        super(props, context);

        HeroPresenter.bindView(this);

        this.className = "HeroSectionView";

        this.state = {
            displayGetNotifiedSection: false,
            displayOutroMessage: false
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", () => {
            const requiredYOffset = (window.innerHeight) || (document.documentElement.clientHeight);
            const requiredYOffsetStart = (requiredYOffset / 4);

            const currentYOffset = window.pageYOffset;


            if (currentYOffset > this._prevScrollPosition) {
                //Scrolling down
                if (currentYOffset > requiredYOffsetStart) {
                    document.querySelector("." + this.className).classList.remove("show");
                    document.querySelector("." + this.className).classList.add("hide");
                }
                if (currentYOffset > requiredYOffsetStart) {
                    document.querySelector("." + this.className).classList.remove("fixed");
                }
            }
            else if (currentYOffset < this._prevScrollPosition) {
                //Scrolling up
                if (currentYOffset < requiredYOffset) {
                    document.querySelector("." + this.className).classList.add("show");
                    document.querySelector("." + this.className).classList.remove("hide");
                }
                if (currentYOffset < requiredYOffsetStart) {
                    document.querySelector("." + this.className).classList.add("fixed");
                }
            }

            this._prevScrollPosition = currentYOffset;
        });
    }

    displayOutroMessage() {
        this.setState({
            displayOutroMessage: true
        });
    }

    hideOutroMessage() {
        this.setState({
            displayOutroMessage: false
        });
    }

    render() {
        return (
            <div className={this.className + " fixed show"} >
                < MediaContentView />
                <div className={"scrollIcon--wrapper"}>
                    <div className="scrollIcon__container">
                        <div className="scrollIcon">
                        </div>
                    </div>
                    <p>SCROLL DOWN FOR MORE</p>
                </div>
                {this.state.displayOutroMessage ? <OutroMessageView /> : ""}
            </div>
        );
    }
}