import LandingPagePresenter from "./HeroPresenter";
import RegisterRepository from "../../../../infrastructure/repository/RegisterRepository";
import { GetNotifiedView } from "../component/GetNotified/GetNotifiedView";
import {auth, provider} from "../../../../config/firebase";


const GetNotifiedPresenter = (() => {

    let _view = null;

    const bindView = (view) => {
        _view = view;
    }

    const bindGetNotifiedBtnView = (getNotifiedBtnView) => {
        LandingPagePresenter.bindGetNotifiedBtnView(getNotifiedBtnView);
    }

    const open = () => {
        LandingPagePresenter.initGetNotifiedView();
    }
    const close = () => {
        LandingPagePresenter.closeGetNotified();
    }

    const registerUser = (email) => {
        return RegisterRepository().registerEmail(email);
    }


    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const onRegister = (email) => {

        if (email !== "" && validateEmail(email)) {

            registerUser(email)
                .then((emailObj) => {
                    onRegisterSuccess(emailObj);
                })
                .catch(
                    (err) => {
                        console.log(err);
                        _view.displayGenericError();
                    }
                );
        }
        else {
            _view.displayEmailError();
        }
    }

    const onRegisterSuccess = (emailObj) => {

        _view.setViewState(GetNotifiedView.VIEW_STATE__SUCCESS);
    }

    const registerWithGoogle = () => {
        return new Promise(
            (resolve, reject) => {
                auth.signInWithPopup(provider)
                    .then((res) => {
                        registerUser(res.user.email)
                            .then((emailObj) => {
                                onRegisterSuccess(emailObj);
                                resolve();
                            })
                            .catch(
                                (err) => {
                                    console.log(err);
                                    _view.displayGenericError();
                                }
                            );
                    })
                    .catch((err) => {
                        reject(err);
                    })
            }
        );
    }

    return {
        bindView,
        bindGetNotifiedBtnView,
        open,
        close,
        onRegister,
        registerWithGoogle
    }

})()

export default GetNotifiedPresenter;