import React, {Component} from 'react';

class CrowdfundingExtCard extends Component {
    static className = Object.freeze("CrowdfundingExtCard");



    render() {
        return (
            <div className={CrowdfundingExtCard.className + " column is-narrow"}>
                <img
                    src={"https://folkeinvestmedia.blob.core.windows.net/public/Folkeinvest-2019-logo.png"}
                    alt={"folkeinvest logo"}
                    width={"250px"}
                />
                <iframe
                    className={"fiFrame"}
                    title={"Watch Vault x Folkeinvest"}
                    src="https://folkeinvest.no/kampanjekort/watch-vault-as"
                    frameBorder="0"
                />
            </div>
        );
    }
}

export default CrowdfundingExtCard;