import React, {Component} from 'react';
import AboutAppIntroImg from "./AboutAppIntroImg";
import AboutAppIntroText from "./AboutAppIntroText";

class AboutAppIntro extends Component {
    static className = Object.freeze("AboutAppIntro");



    render() {
        return (
            <div className={AboutAppIntro.className}>
                <div className="is-hidden-mobile AboutApp--container">
                    <div className="columns contentContainer">
                        <div className="column">
                            <AboutAppIntroText />
                        </div>
                        <div className="column imgContainer">
                            <AboutAppIntroImg />
                        </div>
                    </div>
                </div>
                <div className="is-hidden-tablet AboutApp--container">
                    <AboutAppIntroImg />
                    <AboutAppIntroText />
                </div>
            </div>
        );
    }
}

export default AboutAppIntro;