import React, {Component} from 'react';
import AboutAppVaultImg from "./AboutAppVaultImg";
import AboutAppVaultText from "./AboutAppVaultText";
import AboutAppVaultHeader from "./AboutAppVaultHeader";


class AboutAppVault extends Component {
    static className = Object.freeze("AboutAppVault");



    render() {
        return (
            <div className={AboutAppVault.className}>
                <AboutAppVaultHeader />
                <div className="is-hidden-mobile AboutApp--container">
                    <div className="columns contentContainer">
                        <div className="column imgContainer">
                            <AboutAppVaultImg />
                        </div>
                        <div className="column">
                            <AboutAppVaultText />
                        </div>
                    </div>
                </div>
                <div className="is-hidden-tablet AboutApp--container">
                    <AboutAppVaultImg />
                    <AboutAppVaultText />
                </div>
            </div>
        );
    }
}

export default AboutAppVault;