import React from 'react';
import DownloadFabButton from "./DownloadFabButton";

function DownloadFabContainer() {
    return (
        <div className={"DownloadFab__container"}>
            <DownloadFabButton />
        </div>
    );
}

export default DownloadFabContainer;