import React, {Component} from 'react';

import '../../../../styles/GetNotifiedBtn.css'

class AboutAppSmartPriceText extends Component {
    static className = Object.freeze("AboutAppSmartPriceText");


    /**
     * @private
     * @param {Event} e
     */
    _onDownloadBtnClick(e) {
        //GetNotifiedPresenter.open();
        window.open("https://onelink.to/watchvault", "_blank");
    }

    render() {
        return (
            <div className={AboutAppSmartPriceText.className}>
                <p>Buy your long-anticipated watch from world famous brands, authorized dealers and private collectors without hesitation. Every watch that has gone through verification process is guaranteed to be <b>authentic</b> and our price analytics will let you make a smart decision according to current price trends. You can instantly purchase watches and add them to your collection or <b>win</b> by participating in an <b>auction</b>. Your collection is evaluated weekly and you can trade a watch to gain profit or simply watch your legacy grow. </p>
                <br/>
                <p><b>Join the world´s first social marketplace for watches today!</b></p>

                <div className="GetNotifiedBtn--wrapper">
                    <div
                        className={"GetNotifiedBtnView GetNotifiedBtn"}
                        onClick={(e) => {
                            //GetNotifiedPresenter.open();
                            this._onDownloadBtnClick(e);
                        }}
                    >
                        <p>DOWNLOAD</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutAppSmartPriceText;