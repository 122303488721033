import React, {Component} from 'react';
import SmartPriceImage from "../../../../../../../assets/screenshot-listingAnalytics.png"

class AboutAppSmartPriceImg extends Component {
    static className = Object.freeze("AboutAppSmartPriceImg");



    render() {
        return (
            <div className={AboutAppSmartPriceImg.className}>
                <img src={SmartPriceImage} alt={""}/>
            </div>
        );
    }
}

export default AboutAppSmartPriceImg;