import React, {Component} from 'react';
import AboutAppWatchImg from "./AboutAppWatchImg";
import AboutAppWatchText from "./AboutAppWatchText";
import AboutAppWatchHeader from "./AboutAppWatchHeader";


class AboutAppWatch extends Component {
    static className = Object.freeze("AboutAppWatch");



    render() {
        return (
            <div className={AboutAppWatch.className}>
                <AboutAppWatchHeader />
                <div className="is-hidden-mobile AboutApp--container">
                    <div className="columns contentContainer">
                        <div className="column">
                            <AboutAppWatchText />
                        </div>
                        <div className="column imgContainer">
                            <AboutAppWatchImg />
                        </div>
                    </div>
                </div>
                <div className="is-hidden-tablet AboutApp--container">
                    <AboutAppWatchImg />
                    <AboutAppWatchText />
                </div>
            </div>
        );
    }
}

export default AboutAppWatch;