import React, {Component} from 'react';

class AboutAppSocializeHeader extends Component {
    static className = Object.freeze("AboutAppSocializeHeader");



    render() {
        return (
            <div className={AboutAppSocializeHeader.className}>
                <h4>SOCIALIZE</h4>
            </div>
        );
    }
}

export default AboutAppSocializeHeader;