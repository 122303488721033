import React, {Component} from 'react';
import LogoWidget from "./widget/LogoWidget";
import NavigationWidget from "./widget/NavigationWidget";

import './styles/DesktopHeader.css'

class DesktopHeaderLayout extends Component {
    static className = Object.freeze("DesktopHeaderLayout");


    render() {
        return (
            <div className={DesktopHeaderLayout.className}>
                <LogoWidget />
                <NavigationWidget />
            </div>
        );
    }
}

export default DesktopHeaderLayout;