import React, {Component} from 'react';

import '../styles/OutroMessageView.css'

class OutroMessageView extends Component {

    constructor(props, context) {
        super(props, context);
        this.className = "OutroMessageView";
    }

    render() {
        return (
            <div className={this.className}>
                <div className={this.className + "__text"}>
                    <h1>The ultimate watch app</h1>
                </div>
            </div>
        );
    }
}

export default OutroMessageView;