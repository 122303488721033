
const FirebaseConfig = {
    apiKey: "AIzaSyA1RiBQcTnjYaU2ostOxF_3DHS8cDeA-rw",
    authDomain: "watchvault-lp.firebaseapp.com",
    projectId: "watchvault-lp",
    storageBucket: "watchvault-lp.appspot.com",
    messagingSenderId: "567562666047",
    appId: "1:567562666047:web:a0c83af8cd5612c175788a",
    measurementId: "G-4Y2511ZHBM"
};

export default FirebaseConfig;