import React, {Component} from 'react';
import LoginImage from "../../../../../../../assets/Screenshot_login.png"

class AboutAppIntroImg extends Component {
    static className = Object.freeze("AboutAppIntroImg");



    render() {
        return (
            <div className={AboutAppIntroImg.className}>
                <img src={LoginImage} alt={""}/>
            </div>
        );
    }
}

export default AboutAppIntroImg;