import React, {Component} from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

import "../styles/NavigationSocialMediaWidget.css"


class NavigationSocialMediaWidget extends Component {
    static className = Object.freeze("NavigationSocialMediaWidget");


    constructor(props, context) {
        super(props, context);

        this.state = {
            style: {}
        }

        NavigationSocialMediaWidget.__instance = this;
    }

    /**
     * @return {NavigationSocialMediaWidget}
     */
    static getInstance() {
        return NavigationSocialMediaWidget.__instance;
    }

    /**
     * @private
     * @param {string} url - URL to open in new tab
     */
    _openNewTab(url) {
        window.open(
            url,
            "_blank"
        ).focus();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onInstagramBtnClick(e) {
        this._openNewTab("https://www.instagram.com/watch__vault/");
    }

    /**
     * @private
     * @param {Event} e
     */
    _onTwitterBtnClick(e) {
        this._openNewTab("https://twitter.com/watch__vault");
    }

    /**
     * @private
     * @param {Event} e
     */
    _onLinkedInBtnClick(e) {
        this._openNewTab("https://www.linkedin.com/company/watch-vault/?viewAsMember=true");
    }

    /**
     * @private
     * @param {Event} e
     */
    _onYouTubeBtnClick(e) {
        this._openNewTab("https://www.youtube.com/channel/UCiz-7IokMgtZMr0j2uqSaCA");
    }

    /**
     * @public
     * @param {string} color
     */
    setColor(color) {
        const style = {color};
        this.setState({style});
    }

    setColorBlack() {
        this.setColor("#222");
    }

    setColorWhite() {
        this.setColor("#ddd");
    }

    render() {
        return (
            <div className={NavigationSocialMediaWidget.className}>
                <div
                    className={`
                        ${NavigationSocialMediaWidget.className}__instagramIcon 
                        ${NavigationSocialMediaWidget.className}__navBtn 
                        noSelect
                    `}
                    style={this.state.style}
                    onClick={(e) => this._onInstagramBtnClick(e)}
                >
                    <InstagramIcon />
                </div>
                <div
                    className={`
                        ${NavigationSocialMediaWidget.className}__twitterIcon 
                        ${NavigationSocialMediaWidget.className}__navBtn 
                        noSelect
                    `}
                    style={this.state.style}
                    onClick={(e) => this._onTwitterBtnClick(e)}
                >
                    <TwitterIcon />
                </div>
                <div
                    className={`
                        ${NavigationSocialMediaWidget.className}__linkedInIcon 
                        ${NavigationSocialMediaWidget.className}__navBtn 
                        noSelect
                    `}
                    style={this.state.style}
                    onClick={(e) => this._onLinkedInBtnClick(e)}
                >
                    <LinkedInIcon />
                </div>
                <div
                    className={`
                        ${NavigationSocialMediaWidget.className}__youtubeIcon 
                        ${NavigationSocialMediaWidget.className}__navBtn 
                        noSelect
                    `}
                    style={this.state.style}
                    onClick={(e) => this._onYouTubeBtnClick(e)}
                >
                    <YouTubeIcon />
                </div>
            </div>
        );
    }
}

export default NavigationSocialMediaWidget;