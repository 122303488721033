import React, {Component} from 'react';
import { AboutView } from "../../../features/about";
import { CrowdfundingView } from "../../../features/crowdfunding";
import { StaffView } from "../../../features/the-staff";
import NavigationSocialMediaWidget from "./NavigationSocialMediaWidget";
import { PartnersView } from "../../../features/partners";


class NavigationWidget extends Component {
    static className = Object.freeze("NavigationWidget");


    _onDownloadBtnClick(e) {
        //GetNotifiedPresenter.open();
        window.open("https://onelink.to/watchvault", "_blank");
    }

    /**
     * @private
     * @param {Event} e
     */
    _onAboutBtnClick(e) {

        AboutView.getInstance()?.scrollTo();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onCrowdfundingBtnClick(e) {
        CrowdfundingView.getInstance()?.scrollTo();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onStaffBtnClick(e) {
        StaffView.getInstance()?.scrollTo();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onPartnersBtnClick(e) {
        PartnersView.getInstance()?.scrollTo();
    }

    render() {

        return (
            <nav className={NavigationWidget.className}>
                <div
                    className={
                        NavigationWidget.className + "__getAccessBtn "
                        + NavigationWidget.className + "__navBtn"
                    }
                    onClick={(e) => this._onDownloadBtnClick(e)}
                >
                    <h5>DOWNLOAD</h5>
                </div>
                {/*
                <div
                    className={
                        NavigationWidget.className + "__crowdfundingBtn "
                        + NavigationWidget.className + "__navBtn"
                    }
                    onClick={(e) => this._onCrowdfundingBtnClick(e)}
                >
                    <h5>CROWDFUNDING</h5>
                </div>
                */}
                <div
                    className={
                        NavigationWidget.className + "__aboutAppBtn "
                        + NavigationWidget.className + "__navBtn"
                    }
                    onClick={(e) => this._onAboutBtnClick(e)}
                >
                    <h5>ABOUT</h5>
                </div>
                <div
                    className={
                        NavigationWidget.className + "__staffBtn "
                        + NavigationWidget.className + "__navBtn"
                    }
                    onClick={(e) => this._onStaffBtnClick(e)}
                >
                    <h5>STAFF</h5>
                </div>
                <div
                    className={
                        NavigationWidget.className + "__partnersBtn "
                        + NavigationWidget.className + "__navBtn"
                    }
                    onClick={(e) => this._onPartnersBtnClick(e)}
                >
                    <h5>PARTNERS</h5>
                </div>
                <NavigationSocialMediaWidget />
            </nav>
        );
    }
}

export default NavigationWidget;