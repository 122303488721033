import React, {Component} from 'react';

class AboutAppSmartPriceHeader extends Component {
    static className = Object.freeze("AboutAppSmartPriceHeader");



    render() {
        return (
            <div className={AboutAppSmartPriceHeader.className}>
                <h4>MARKET WITH A SMART PRICE</h4>
            </div>
        );
    }
}

export default AboutAppSmartPriceHeader;