import React, {Component} from 'react';
import AboutAppWidget from "./widgets/about-app/AboutAppWidget";
import ScrollHandler from "../../common/utils/scroll-handler";

import "./styles/AboutView.css"

export class AboutView extends Component {
    static className = Object.freeze("AboutView");


    constructor(props, context) {
        super(props, context);
        AboutView.__instance = this;
    }

    /**
     * @return {AboutView}
     */
    static getInstance() {
        return AboutView.__instance;
    }

    scrollTo() {
        ScrollHandler.lockAnim(true);

        document.querySelector(`.${AboutView.className}`).scrollIntoView();

        document.querySelector("." + AboutView.className)?.classList.remove("about-app--hide");
        document.querySelector("." + AboutView.className)?.classList.add("about-app--show");

        setTimeout(
            () => {
                //Reset anim lock after 2 sec
                ScrollHandler.lockAnim(false);
            },
            2000
        )
    }

    componentDidMount() {
        document.querySelector("." + AboutView.className)?.classList.add("about-app--hide");

    }

    render() {
        return (
            <section className={AboutView.className}>
                <AboutAppWidget />
                {/* <AboutUsWidget/> */}
            </section>
        );
    }
}