import React, {Component} from 'react';

class AboutAppHeader extends Component {
    static className = Object.freeze("AboutAppHeader");



    render() {
        return (
            <div className={AboutAppHeader.className}>
                <h3>ABOUT</h3>
            </div>
        );
    }
}

export default AboutAppHeader;