import React, {Component} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch} from "react-router-dom"
import ScrollHandler from "./common/utils/scroll-handler";
import { HomePage } from "./pages/home";
import './App.css';

const ReactAppConfig = {
    appName: "",
}

export function renderUI() {
    startReactApp();
}

export function startReactApp(config = ReactAppConfig) {
    ReactDOM.render(
        <React.StrictMode>
            <App name={config?.appName} />
        </React.StrictMode>,
        document.getElementById('root')
    );
}

export default class App extends Component {
    props = ReactAppConfig;

    componentDidMount() {
        ScrollHandler.start();
    }

    render() {
        return (
            <BrowserRouter>
                <div className={this.props.appName || "App"}>
                    <Switch>
                        <Route path={"/"}>
                            <HomePage />
                        </Route>
                  </Switch>
              </div>
          </BrowserRouter>
        );
    }
}

