import React, {Component} from 'react';

class AboutAppWatchHeader extends Component {
    static className = Object.freeze("AboutAppWatchHeader");



    render() {
        return (
            <div className={AboutAppWatchHeader.className}>
                <h4>WATCH</h4>
            </div>
        );
    }
}

export default AboutAppWatchHeader;