import React, {Component} from 'react';
import ChatImage from "../../../../../../../assets/screenshot-chat.png"

class AboutAppSocializeImg extends Component {
    static className = Object.freeze("AboutAppSocializeImg");



    render() {
        return (
            <div className={AboutAppSocializeImg.className}>
                <img src={ChatImage} alt={""}/>
            </div>
        );
    }
}

export default AboutAppSocializeImg;