import React, {Component} from "react";
import { AboutView } from "../../../features/about";
import { CrowdfundingView } from "../../../features/crowdfunding";
import { StaffView } from "../../../features/the-staff";
import NavigationSocialMediaWidget from "./NavigationSocialMediaWidget";
import { PartnersView } from "../../../features/partners";

import "../styles/MobileNavigationMenu.css"

export default class MobileNavigationMenuWidget extends Component {
    static className = Object.freeze("MobileNavigationMenuWidget");



    constructor(props, context) {
        super(props, context);
        this.onMenuExit = () => {
            this._onMenuExit();
            props.onMenuExit();
        };
    }


    componentDidMount() {
        document.body.classList.add("noScroll");
    }

    /**
     * @private
     */
    _onMenuExit() {
        document.body.classList.remove("noScroll");
    }

    /**
     * @private
     * @param {Event} e
     */
    _onDownloadBtnClick(e) {
        //GetNotifiedPresenter.open();
        window.open("https://onelink.to/watchvault", "_blank");

        this.onMenuExit();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onAboutBtnClick(e) {
        this.onMenuExit();

        AboutView.getInstance()?.scrollTo();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onCrowdfundingBtnClick(e) {
        this.onMenuExit();

        CrowdfundingView.getInstance()?.scrollTo();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onStaffBtnClick(e) {
        this.onMenuExit();

        StaffView.getInstance()?.scrollTo();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onPartnersBtnClick(e) {
        this.onMenuExit();

        PartnersView.getInstance()?.scrollTo();
    }

    render() {

        return (
            <nav
                className={MobileNavigationMenuWidget.className}
                onClick={(e) => this.onMenuExit()}
            >
                <div className={MobileNavigationMenuWidget.className + "--wrapper"}>
                    <div
                        className={
                            MobileNavigationMenuWidget.className + "__socialMedia "
                            + MobileNavigationMenuWidget.className + "__navBtn noSelect"
                        }
                    >
                        <NavigationSocialMediaWidget />
                    </div>
                    <div className={`${MobileNavigationMenuWidget.className}__navBtns--wrapper`}>
                        <div
                            className={
                                MobileNavigationMenuWidget.className + "__getAccessBtn "
                                + MobileNavigationMenuWidget.className + "__navBtn noSelect"
                            }
                            onClick={(e) => this._onDownloadBtnClick(e)}
                        >
                            <h6>DOWNLOAD</h6>
                        </div>
                        {/*
                        <div
                            className={
                                MobileNavigationMenuWidget.className + "__crowdfundingBtn "
                                + MobileNavigationMenuWidget.className + "__navBtn noSelect"
                            }
                            onClick={(e) => this._onCrowdfundingBtnClick(e)}
                        >
                            <h6>CROWDFUNDING</h6>
                        </div>
                        */}
                        <div
                            className={
                                MobileNavigationMenuWidget.className + "__aboutAppBtn "
                                + MobileNavigationMenuWidget.className + "__navBtn noSelect"
                            }
                            onClick={(e) => this._onAboutBtnClick(e)}
                        >
                            <h6>ABOUT</h6>
                        </div>
                        <div
                            className={
                                MobileNavigationMenuWidget.className + "__staffBtn "
                                + MobileNavigationMenuWidget.className + "__navBtn noSelect"
                            }
                            onClick={(e) => this._onStaffBtnClick(e)}
                        >
                            <h6>STAFF</h6>
                        </div>
                        <div
                            className={
                                MobileNavigationMenuWidget.className + "__partnersBtn "
                                + MobileNavigationMenuWidget.className + "__navBtn noSelect"
                            }
                            onClick={(e) => this._onPartnersBtnClick(e)}
                        >
                            <h6>PARTNERS</h6>
                        </div>
                        <div className={MobileNavigationMenuWidget.className + "__backBtn--wrapper"}>
                            <div className={MobileNavigationMenuWidget.className + "__backBtn noSelect"}>
                                <h6>BACK</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}