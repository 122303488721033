import React, {Component} from 'react';

class StaffMemberWidget extends Component {
    static className = Object.freeze("StaffMemberWidget");


    constructor(props, context) {
        super(props, context);
        this.state = {
            name: props.name || "Name",
            jobTitle: props.jobTitle || "EMPLOYEE",
            imgUrl: props.imgUrl || "https://i.ibb.co/D47tJKt/logotekst-hvitsort.jpg",
            text: props.children || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris.",
            email: props.email ??  "info@watchvault.no",
            phoneNum: props.phoneNum || ""
        }
    }

    render() {
        return (
            <div className={StaffMemberWidget.className + " column is-4"}>
                <div className="card">
                    <div className="card-image">
                        <figure className="image is-4by3">
                            <img src={this.state.imgUrl} alt="profile" />
                        </figure>
                    </div>
                    <div className="card-content">
                        <div className="media">
                            <div className="media-content">
                                <p className="title is-5">{this.state.name}</p>
                                <p className="subtitle is-6">{this.state.jobTitle}</p>
                            </div>
                        </div>

                        <div className="content">
                            {this.state.text}
                            <br/>
                            <b>{this.state.email}</b>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StaffMemberWidget;