import { AboutView } from "../../features/about";
import smoothscroll from "smoothscroll-polyfill";


export default
class ScrollHandler {
    /**
     * @private
     * @type {boolean}
     */
    static operating;


    /**@private*/
    static _prevScrollPos = 0;

    /**@private*/
    static _inAnimation = false;

    /**@private*/
    static _animLock = false;

    /**
     * @private
     * @type {number}
     * */
    static _animLockPos = 0;

    /**@private*/
    static _targetScrollPos;



    /**
     * @private
     * @type {function}
     */
    static handler = () => {
        const requiredYOffset = (window.innerHeight) || (document.documentElement.clientHeight);
        const requiredYOffsetStart = (requiredYOffset / 4);


        const currentOffset = window.pageYOffset || document.documentElement.offsetTop;


        if (!ScrollHandler._animLock) {
            if (!ScrollHandler._inAnimation) {
                if (currentOffset > ScrollHandler._prevScrollPos) {
                    //Going down

                    if (currentOffset > requiredYOffsetStart) {
                        document.querySelector("." + AboutView.className)?.classList.remove("about-app--hide");
                        document.querySelector("." + AboutView.className)?.classList.add("about-app--show");

                        if (currentOffset < requiredYOffset && currentOffset > requiredYOffsetStart) {
                            window.scrollTo({
                                top: requiredYOffset,
                                behavior: "smooth"
                            });
                            ScrollHandler._targetScrollPos = requiredYOffset;
                            ScrollHandler._inAnimation = true;
                        }
                    }
                    else {
                        document.querySelector("." + AboutView.className)?.classList.remove("about-app--show");
                        document.querySelector("." + AboutView.className)?.classList.add("about-app--hide");
                    }
                }
                else {
                    //Going up

                    if (currentOffset < requiredYOffset) {
                        document.querySelector("." + AboutView.className)?.classList.remove("about-app--show");
                        document.querySelector("." + AboutView.className)?.classList.add("about-app--hide");

                        if (currentOffset < requiredYOffset && currentOffset > requiredYOffsetStart) {
                            window.scrollTo({
                                top: 1,
                                behavior: "smooth"
                            });
                            ScrollHandler._targetScrollPos = 1;
                            ScrollHandler._inAnimation = true;
                        }
                    }
                    else {
                        document.querySelector("." + AboutView.className)?.classList.remove("about-app--hide");
                        document.querySelector("." + AboutView.className)?.classList.add("about-app--show");
                    }
                }
            }
            else {
                if (currentOffset > requiredYOffset || currentOffset < requiredYOffsetStart) ScrollHandler._inAnimation = false;
            }

            ScrollHandler._prevScrollPos = currentOffset;
        }
        else {
            if (currentOffset === ScrollHandler._animLockPos - 1) {
                ScrollHandler._animLock = false;
            }
        }
    };

    /**
     *
     * @param {number} animLockPos
     */
    static setAnimLockPos(animLockPos) {
        ScrollHandler._animLockPos = animLockPos;
    }

    static lockAnim(isActive) {
        ScrollHandler._animLock = isActive ?? true;
    }

    static isOperating() {
        return ScrollHandler.operating;
    }

    static start() {
        window.addEventListener("scroll", ScrollHandler.handler);

        ScrollHandler.operating = true;

        smoothscroll.polyfill();
        window.__forceSmoothScrollPolyfill__ = true;
    }

    static close() {
        window.removeEventListener("scroll", ScrollHandler.handler)

        ScrollHandler.operating = false;
    }
}