import React, {Component} from 'react';

class PartnerLogo extends Component {
    static className = Object.freeze("PartnerLogo");


    /**
     * @private
     * @param {string} url - URL to open in new tab
     */
    _openNewTab(url) {
        window.open(
            url,
            "_blank"
        ).focus();
    }

    /**
     * @private
     * @param {Event} e
     */
    _onLogoClicked(e) {
        if (this.props.url) {
            this._openNewTab(this.props.url);
        }
    }

    render() {
        return (
            <div
                className={PartnerLogo.className + " column"}
                onClick={(e) => this._onLogoClicked(e)}
            >
                {this.props.children}
            </div>
        );
    }
}

export default PartnerLogo;