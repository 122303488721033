import React, {Component} from 'react';

class SuccessViewWidget extends Component {

    constructor(props, context) {
        super(props, context);
        this.className = "SuccessViewWidget";
    }

    render() {
        return (
            <>
                <div className={this.className + " is-hidden-mobile"}>
                    <h2>Congratulations!</h2>
                    <h5>You are among the first to gain access to the <br/>Watch Vault app.<br/><br/> We will notify you once it's ready to launch.</h5>
                    <p>(Click anywhere to exit..)</p>
                </div>
                <div className={this.className + "--mobile is-hidden-tablet"}>
                    <h2>Congratulations!</h2>
                    <h5>You are among the first to gain access to the Watch Vault app. We will notify you once it's ready to launch.</h5>
                    <p>(Click anywhere to exit..)</p>
                </div>
            </>
        );
    }
}

export default SuccessViewWidget;